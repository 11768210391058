import React from "react";
import { cn } from "../../lib/utils";
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from "../atoms/breadcrumbs";
import { Header } from "../molecules/header/Header";
import type { IBreadcrumbNavItem } from "./api";
export interface IAbstractDashboardLayout {
  className?: string;
  breadcrumbs: IBreadcrumbNavItem[];
  actions?: React.ReactNode;
  leftPanel?: React.ReactNode;
  mainPanel: React.ReactNode;
}
export const AbstractDashboardLayout: React.FC<IAbstractDashboardLayout> = ({
  className,
  breadcrumbs,
  mainPanel,
  leftPanel,
  actions
}) => {
  const breadcrumbContent = <Breadcrumb>
      {breadcrumbs.map((breadcrumb, idx) => <BreadcrumbItem isCurrentPage={breadcrumb.isCurrentPage} key={idx}>
          {breadcrumb.linkRenderer ? breadcrumb.linkRenderer(<BreadcrumbLink isCurrentPage={breadcrumb.isCurrentPage}>{breadcrumb.text}</BreadcrumbLink>) : <BreadcrumbLink isCurrentPage={breadcrumb.isCurrentPage}>{breadcrumb.text}</BreadcrumbLink>}
        </BreadcrumbItem>)}
    </Breadcrumb>;
  return <main className={cn("text-ink flex h-[100vh] w-[100vw] flex-col overflow-hidden", className)} data-sentry-component="AbstractDashboardLayout" data-sentry-source-file="AbstractDashboardLayout.tsx">
      <Header leftContent={breadcrumbContent} rightContent={actions} data-sentry-element="Header" data-sentry-source-file="AbstractDashboardLayout.tsx" />
      <div className="flex h-0 max-h-full shrink grow">
        {leftPanel != null && <aside className="flex h-full flex-col">{leftPanel}</aside>}
        <div className="flex w-full grow flex-col">{mainPanel}</div>
      </div>
    </main>;
};