// Note: this is a wrapper around all icons from lucide-react
// so we can enforce certain standards on all icons (eg stroke width)
// See https://lucide.dev/guide/packages/lucide-react#nextjs-example

// Ignore eslint errors here because we need to import to wrap the icons

// eslint-disable-next-line no-restricted-imports
import type { LucideProps } from "lucide-react";
// eslint-disable-next-line no-restricted-imports
import dynamicIconImports from "lucide-react/dynamicIconImports";
import dynamic from "next/dynamic";
import React from "react";
import { cn } from "../../lib/utils";
export type IIconNames = keyof typeof dynamicIconImports;
export interface IIconProps extends Omit<LucideProps, "size"> {
  name: IIconNames;
}
const InternalIcon = ({
  name,
  className,
  ...props
}: IIconProps): JSX.Element => {
  const LucideIcon = dynamic(dynamicIconImports[name]);
  return <LucideIcon strokeWidth={1.5} absoluteStrokeWidth={true} className={cn("size-4", className)} {...props} data-sentry-element="LucideIcon" data-sentry-component="InternalIcon" data-sentry-source-file="icon.tsx" />;
};
export const Icon = React.memo(InternalIcon);