import { useCallback, useState } from "react";
export type IScrollState = "start" | "middle" | "end";
export function useScrollState(direction: "horizontal" | "vertical", handleScroll?: (e: React.UIEvent<HTMLElement>) => void): [IScrollState, (e: React.UIEvent<HTMLElement>) => void] {
  const [scrollState, setScrollState] = useState<IScrollState>("start");
  const innerHandleScroll = useCallback((e: React.UIEvent<HTMLElement>) => {
    handleScroll && handleScroll(e);
    const target = e.target as HTMLElement;
    const scrollDistance = direction === "horizontal" ? target.scrollLeft : target.scrollTop;
    const scrollSize = direction === "horizontal" ? target.scrollWidth : target.scrollHeight;
    const offsetSize = direction === "horizontal" ? target.offsetWidth : target.offsetHeight;
    if (scrollDistance === 0) {
      setScrollState("start");
    } else if (scrollSize - offsetSize - scrollDistance < 1) {
      setScrollState("end");
    } else {
      setScrollState("middle");
    }
  }, [handleScroll, direction]);
  return [scrollState, innerHandleScroll];
}