import type { VariantProps } from "class-variance-authority";
import { cva } from "class-variance-authority";
import * as React from "react";
import { cn } from "../../lib/utils";
const RawTable = React.forwardRef<HTMLTableElement, React.HTMLAttributes<HTMLTableElement>>(({
  className,
  ...props
}, ref) => <div className="w-full overflow-auto">
      <table ref={ref} className={cn("w-full caption-bottom text-base", className)} {...props} />
    </div>);
RawTable.displayName = "Table";
const TableHeader = React.forwardRef<HTMLTableSectionElement, React.HTMLAttributes<HTMLTableSectionElement>>(({
  className,
  ...props
}, ref) => <thead ref={ref} className={cn("[&_tr]:border-b", className)} {...props} />);
TableHeader.displayName = "TableHeader";
const TableBody = React.forwardRef<HTMLTableSectionElement, React.HTMLAttributes<HTMLTableSectionElement>>(({
  className,
  ...props
}, ref) => <tbody ref={ref} className={cn("[&_tr:last-child]:border-0", className)} {...props} />);
TableBody.displayName = "TableBody";
const TableFooter = React.forwardRef<HTMLTableSectionElement, React.HTMLAttributes<HTMLTableSectionElement>>(({
  className,
  ...props
}, ref) => <tfoot ref={ref} className={cn("bg-primary text-primary-foreground font-medium", className)} {...props} />);
TableFooter.displayName = "TableFooter";
export const TABLE_ROW_COLOR_CLASSES = "hover:bg-muted data-[state=selected]:bg-secondary";
export const TABLE_ROW_GROUP_COLOR_CLASSES = "";
// "group-hover:bg-muted group-data-[state=selected]:bg-secondary transition-colors";

const TableRow = React.forwardRef<HTMLTableRowElement, React.HTMLAttributes<HTMLTableRowElement>>(({
  className,
  ...props
}, ref) => <tr ref={ref} className={cn(TABLE_ROW_COLOR_CLASSES, "group border-b", className)} {...props} />);
TableRow.displayName = "TableRow";
const tableHeadVariants = cva("h-12 px-4 text-left align-middle text-xs font-medium uppercase tracking-wide text-muted-foreground [&:has([role=checkbox])]:pr-0", {
  variants: {
    padding: {
      default: "px-4",
      noPadding: "p-0"
    }
  },
  defaultVariants: {
    padding: "default"
  }
});
export type ITableHeadVariants = VariantProps<typeof tableHeadVariants>;
const TableHead = React.forwardRef<HTMLTableCellElement, React.ThHTMLAttributes<HTMLTableCellElement> & VariantProps<typeof tableHeadVariants>>(({
  className,
  padding,
  ...props
}, ref) => <th ref={ref} className={cn(tableHeadVariants({
  padding
}), className)} {...props} />);
TableHead.displayName = "TableHead";
type ITableCellDensity = "default" | "airy" | "compact";
const tableCellVariants = cva("align-middle [&:has([role=checkbox])]:pr-0", {
  variants: {
    backgroundColor: {
      lilac: "bg-pastel-lilac-background group-hover:bg-pastel-lilac-background/80 group-data-[state=selected]:bg-pastel-lilac-background/80",
      purple: "bg-pastel-purple-background group-hover:bg-pastel-purple-background/80 group-data-[state=selected]:bg-pastel-purple-background/80",
      pink: "bg-pastel-pink-background group-hover:bg-pastel-pink-background/80 group-data-[state=selected]:bg-pastel-pink-background/80",
      red: "bg-pastel-red-background group-hover:bg-pastel-red-background/80 group-data-[state=selected]:bg-pastel-red-background/80",
      orange: "bg-pastel-orange-background group-hover:bg-pastel-orange-background/80 group-data-[state=selected]:bg-pastel-orange-background/80",
      brown: "bg-pastel-brown-background group-hover:bg-pastel-brown-background/80 group-data-[state=selected]:bg-pastel-brown-background/80",
      yellow: "bg-pastel-yellow-background group-hover:bg-pastel-yellow-background/80 group-data-[state=selected]:bg-pastel-yellow-background/80",
      lime: "bg-pastel-lime-background group-hover:bg-pastel-lime-background/80 group-data-[state=selected]:bg-pastel-lime-background/80",
      sage: "bg-pastel-sage-background group-hover:bg-pastel-sage-background/80 group-data-[state=selected]:bg-pastel-sage-background/80",
      green: "bg-pastel-green-background group-hover:bg-pastel-green-background/80 group-data-[state=selected]:bg-pastel-green-background/80",
      emerald: "bg-pastel-emerald-background group-hover:bg-pastel-emerald-background/80 group-data-[state=selected]:bg-pastel-emerald-background/80",
      teal: "bg-pastel-teal-background group-hover:bg-pastel-teal-background/80 group-data-[state=selected]:bg-pastel-teal-background/80",
      blue: "bg-pastel-blue-background group-hover:bg-pastel-blue-background/80 group-data-[state=selected]:bg-pastel-blue-background/80",
      neutral: "bg-pastel-neutral-background group-hover:bg-pastel-neutral-background/80 group-data-[state=selected]:bg-pastel-neutral-background/80",
      transparent: "bg-paper group-hover:bg-muted-background group-data-[state=selected]:bg-secondary"
    },
    padding: {
      default: "px-4 py-1.5",
      noPadding: "p-0"
    },
    density: {
      default: "h-10",
      airy: "h-14",
      compact: "h-6 text-sm"
    }
  },
  defaultVariants: {
    padding: "default",
    density: "default",
    backgroundColor: "transparent"
  }
});
export type ITableCellVariants = VariantProps<typeof tableCellVariants>;
const TableCell = React.forwardRef<HTMLTableCellElement, React.TdHTMLAttributes<HTMLTableCellElement> & VariantProps<typeof tableCellVariants>>(({
  className,
  density,
  backgroundColor,
  padding,
  ...props
}, ref) => {
  return <td ref={ref} className={cn(tableCellVariants({
    density,
    padding,
    backgroundColor
  }), className)} {...props} />;
});
TableCell.displayName = "TableCell";
const TableCaption = React.forwardRef<HTMLTableCaptionElement, React.HTMLAttributes<HTMLTableCaptionElement>>(({
  className,
  ...props
}, ref) => <caption ref={ref} className={cn("text-muted-foreground mt-4 text-sm", className)} {...props} />);
TableCaption.displayName = "TableCaption";
export { RawTable, TableBody, TableCaption, TableCell, TableFooter, TableHead, TableHeader, TableRow };
export type { ITableCellDensity };