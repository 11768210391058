import React from "react";
import { cn } from "../../lib/utils";
export const Spinner: React.FC<{
  className?: string;
}> = ({
  className
}) => <span className={cn("inline-block h-4 w-4 text-current", className)} data-sentry-component="Spinner" data-sentry-source-file="spinner.tsx">
    <svg viewBox="0 0 100 100" data-sentry-element="svg" data-sentry-source-file="spinner.tsx">
      <path d="M 50 20 L 80 70 L 20 70 Z" fill="none" stroke="currentColor" strokeWidth="8" strokeDasharray="30" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="spinner.tsx">
        <animate attributeName="stroke-dashoffset" from="180" to="0" dur="3s" repeatCount="indefinite" data-sentry-element="animate" data-sentry-source-file="spinner.tsx" />
        <animateTransform attributeName="transform" attributeType="XML" type="rotate" from="0 50 53" to="360 50 53" dur="3s" repeatCount="indefinite" data-sentry-element="animateTransform" data-sentry-source-file="spinner.tsx" />
      </path>
    </svg>
  </span>;