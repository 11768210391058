import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { Form } from "../atoms/form";
import type { IComment } from "./comment-thread";
import { CommentTextarea } from "./form/CommentTextarea";
interface ICommentInputProps {
  className?: string;
  onComment: (commentText: string) => Promise<{
    id: string;
  }>;
  onGenerateCommentId: () => string;
  setFailedComments: (setter: (comments: IComment[]) => IComment[]) => void;
  setSendingComments: (setter: (comments: IComment[]) => IComment[]) => void;
}
export function CommentInput({
  className,
  onComment,
  onGenerateCommentId,
  setFailedComments,
  setSendingComments
}: ICommentInputProps): JSX.Element {
  const form = useForm<{
    newComment: string | undefined;
  }>({
    defaultValues: {
      newComment: undefined
    }
  });
  const handleSubmit = useCallback(async () => {
    const commentText = form.getValues().newComment;
    if (commentText == null) {
      return;
    }
    const newComment: IComment = {
      id: onGenerateCommentId(),
      author: undefined,
      // Could make a way to render the current user here too
      authorImage: undefined,
      at: new Date(),
      commentText
    };
    setSendingComments(currentSendingComments => currentSendingComments.concat([newComment]));
    form.setValue("newComment", undefined);
    form.reset({
      newComment: ""
    });
    try {
      await onComment(commentText);
    } catch {
      setFailedComments(currentFailedComments => currentFailedComments.concat([newComment]));
    } finally {
      setSendingComments(currentSendingComments => currentSendingComments.filter(c => c.id !== newComment.id));
    }
  }, [setSendingComments, onComment, setFailedComments, form, onGenerateCommentId]);
  return <div className={className} data-sentry-component="CommentInput" data-sentry-source-file="comment-input.tsx">
      <Form {...form} data-sentry-element="Form" data-sentry-source-file="comment-input.tsx">
        <form className="flex w-full flex-col space-y-2 border-t border-accent bg-gray-50 p-2 text-base"
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onSubmit={form.handleSubmit(handleSubmit)} autoFocus={true}>
          <div className="border">
            <CommentTextarea name="newComment" placeholder="Add your update..." data-sentry-element="CommentTextarea" data-sentry-source-file="comment-input.tsx" />
          </div>
        </form>
      </Form>
    </div>;
}
CommentInput.displayName = "CommentInput";