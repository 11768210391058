import * as React from "react";
import { cn } from "../../lib/utils";
import { Loader } from "../molecules/loader/Loader";
import { ByAiAssistant } from "./by-ai-assistant";
import { PhoneInput } from "./phone-input";
type IFile = {
  id: string;
  vendor: "vercel";
  fileName: string;
  fileUrl: string;
  fileSizeInBytes: number;
};
type IColumnField = {
  id: string; // view field id
  type: "column";
  columnId: string;
  label: string;
  value: string | Array<IFile> | null;
  isComputing: boolean;
  isAIAutofilled: boolean;
  isFileType: boolean;
  columnType: "number" | "boolean" | "date" | "timestamp" | "email" | "shortText" | "longText" | "geolocation" | "phone" | "url" | "enum" | "statusEnum" | "file";
};
type ILinkField = {
  id: string; // view field id
  type: "directionalRelation";
  relationId: string;
  label: string;
  links: {
    id: string;
    typeId: string;
    displayName: string;
  }[];
  isAIAutofilled: boolean;
};
export type IDataField = IColumnField | ILinkField;
export interface IDataFieldsProps {
  className?: string;
  fields: IDataField[];
  onLinkSelection?: (id: string, typeId: string) => void;
  fieldHighlightProps?: {
    highlightedViewFieldId: string | undefined;
    highlightViewField: (viewFieldId: string) => void;
  };
}
export const DataFields: React.FC<IDataFieldsProps> = ({
  fields,
  onLinkSelection,
  className,
  fieldHighlightProps
}) => {
  const handleLinkClick = React.useCallback((id: string, typeId: string) => (): void => {
    onLinkSelection?.(id, typeId);
  }, [onLinkSelection]);
  const handleColumnClick = React.useCallback((viewFieldId: string) => (): void => {
    fieldHighlightProps?.highlightViewField(viewFieldId);
  }, [fieldHighlightProps]);
  return <dl className={className} data-sentry-component="DataFields" data-sentry-source-file="data-fields.tsx">
      {fields.map(field => field.type === "column" ? <div key={field.id} className={cn("border-accent grid grid-cols-1 border-b p-4", fieldHighlightProps != null && "cursor-pointer rounded-md border border-transparent hover:bg-gray-100", fieldHighlightProps?.highlightedViewFieldId === field.id && "border-border bg-muted-background border")} onClick={handleColumnClick(field.id)}>
            <div className="flex items-center justify-between">
              <dt className="text-base leading-6 text-muted-foreground">{field.label}</dt>
              {field.isAIAutofilled ? <ByAiAssistant className="shrink-0" /> : null}
            </div>
            {field.isComputing ? <Loader className="mt-1 text-lg leading-6" text={"Computing..."} /> : field.isFileType ? <dd className="mt-1 grow whitespace-normal break-words text-lg leading-6 text-ink">
                {Array.isArray(field.value) ? field.value.map(file => <div key={file.id}>
                        <a href={file.fileUrl} target="_blank" rel="noopener noreferrer" className="underline">
                          {file.fileName}
                        </a>
                      </div>) : "No files uploaded"}
              </dd> : field.columnType === "url" ? <div className="mt-1 grow whitespace-normal break-words text-lg leading-6 text-ink">
                <a href={field.value as string ?? ""} target="_blank" rel="noopener noreferrer" className="text-primary hover:underline">
                  {field.value as string ?? "—"}
                </a>
              </div> : field.columnType === "phone" ? <dd className="mt-1 grow whitespace-normal break-words text-lg leading-6 text-ink">
                {typeof field.value === "string" ? <PhoneInput value={field.value} variant="text" disabled={true} /> : "—"}
              </dd> : <dd className="mt-1 grow whitespace-normal break-words text-lg leading-6 text-ink">
                {typeof field.value === "string" ? field.value : "—"}
              </dd>}
            <div className="flex items-center space-x-1" />
          </div> : <div key={field.relationId} className="grid grid-cols-1 border-b border-accent p-4">
            <div className="flex items-center justify-between">
              <dt className="text-base leading-6 text-muted-foreground">{field.label}</dt>
              {field.isAIAutofilled ? <ByAiAssistant className="shrink-0" /> : null}
            </div>
            {field.links.length === 0 ? "—" : field.links.slice(0, 5).map(link => <dd key={link.id} className="mt-1 whitespace-normal break-words text-lg leading-6 text-ink underline decoration-neutral-300 hover:cursor-pointer" onClick={handleLinkClick(link.id, link.typeId)}>
                    {link.displayName}
                  </dd>)}
            {field.links.length > 5 ? <div className="text-sm text-muted-foreground">+{field.links.length - 10} more</div> : null}
          </div>)}
    </dl>;
};