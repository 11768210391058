"use client";

import * as React from "react";
import type { IScrollState } from "../../hooks/useScrollState";
import { cn } from "../../lib/utils";
interface ISidebarHeaderProps {
  scrollState: IScrollState;
  children: React.ReactNode;
}
export const SidebarHeader: React.FC<ISidebarHeaderProps> = ({
  scrollState,
  children
}) => {
  return <div className={cn("z-10 shrink-0 grow-0", scrollState !== "start" && "shadow-md")} data-sentry-component="SidebarHeader" data-sentry-source-file="sidebar-header.tsx">{children}</div>;
};