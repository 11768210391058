import Link from "next/link";
import type { ILinkRenderer } from "./api";
interface IRoute {
  pathname: string;
  query?: {
    [key: string]: string | string[] | undefined;
  };
  hash?: string | null | undefined;
}
export const nextLinkRenderer = <T extends IRoute,>(href: T, {
  shallow
} = {}): ILinkRenderer => function NextLinkRenderer(children: React.ReactNode) {
  return <Link href={href} passHref={true} shallow={shallow === true}>
        {children}
      </Link>;
};