"use client";

import * as SwitchPrimitives from "@radix-ui/react-switch";
import * as React from "react";
import { cn } from "../../lib/utils";
const Switch = React.forwardRef<React.ElementRef<typeof SwitchPrimitives.Root>, React.ComponentPropsWithoutRef<typeof SwitchPrimitives.Root> & {
  small?: boolean;
}>(({
  className,
  small = false,
  ...props
}, ref) => <SwitchPrimitives.Root className={cn("focus-visible:ring-ring focus-visible:ring-offset-paper data-[state=checked]:bg-primary peer inline-flex shrink-0 cursor-pointer items-center rounded-full border-2 border-transparent transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 data-[state=unchecked]:bg-gray-300", small ? "h-[16px] w-[28px]" : "h-[24px] w-[40px]", className)} {...props} ref={ref}>
    <SwitchPrimitives.Thumb className={cn("bg-paper pointer-events-none block rounded-full shadow-lg ring-0 transition-transform", small ? "h-3 w-3 data-[state=checked]:translate-x-3 data-[state=unchecked]:translate-x-0" : "h-5 w-5 data-[state=checked]:translate-x-4 data-[state=unchecked]:translate-x-0")} />
  </SwitchPrimitives.Root>);
Switch.displayName = SwitchPrimitives.Root.displayName;
export { Switch };