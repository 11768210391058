import type { Column } from "@tanstack/react-table";
import { useCallback } from "react";
import { useMemoDeepCompare } from "../../../hooks/useMemoDeepCompare";
import { cn } from "../../../lib/utils";
import { Badge } from "../../atoms/badge";
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList, CommandSeparator } from "../../atoms/command";
import type { IIconNames } from "../../atoms/icon";
import { Icon } from "../../atoms/icon";
import { Popover, PopoverContent, PopoverTrigger } from "../../atoms/popover";
import { Separator } from "../../atoms/separator";
interface ITableFacetedFilterCommand<TData, TValue> {
  column?: Column<TData, TValue>;
  title?: string;
  options: {
    label: string;
    value: string;
    icon?: IIconNames;
  }[];
}
export function FilterCommand<TData, TValue>({
  column,
  title,
  options
}: ITableFacetedFilterCommand<TData, TValue>): JSX.Element {
  const facets = column?.getFacetedUniqueValues();
  const selectedValues = useMemoDeepCompare(() => new Set(column?.getFilterValue() as string[]), [column]);
  const handleOptionSelect = useCallback((option: {
    label: string;
    value: string;
    icon?: IIconNames;
  }) => (): void => {
    if (selectedValues.has(option.value)) {
      selectedValues.delete(option.value);
    } else {
      selectedValues.add(option.value);
    }
    const filterValues = Array.from(selectedValues);
    column?.setFilterValue(filterValues.length ? filterValues : undefined);
  }, [column, selectedValues]);
  const handleClearFilters = useCallback((): void => {
    column?.setFilterValue(undefined);
  }, [column]);
  return <Command data-sentry-element="Command" data-sentry-component="FilterCommand" data-sentry-source-file="TableFacetedFilter.tsx">
      <CommandInput placeholder={`Search${title != null ? ` ${title}` : ""}...`} data-sentry-element="CommandInput" data-sentry-source-file="TableFacetedFilter.tsx" />
      <CommandList data-sentry-element="CommandList" data-sentry-source-file="TableFacetedFilter.tsx">
        <CommandEmpty data-sentry-element="CommandEmpty" data-sentry-source-file="TableFacetedFilter.tsx">No results found.</CommandEmpty>
        <CommandGroup data-sentry-element="CommandGroup" data-sentry-source-file="TableFacetedFilter.tsx">
          {options.map(option => {
          const isSelected = selectedValues.has(option.value);
          return <CommandItem className="text-sm" key={option.value} onSelect={handleOptionSelect(option)}>
                <div className={cn("border-border mr-2 flex h-4 w-4 items-center justify-center rounded-sm border", isSelected ? "bg-primary text-primary-foreground" : "opacity-50 [&_svg]:invisible")}>
                  <Icon name="check" />
                </div>
                {option.icon ? <Icon name={option.icon} className="mr-2 text-muted-foreground" /> : null}
                <span>{option.label}</span>
                {facets?.get(option.value) != null && <span className="ml-auto flex size-4 items-center justify-center font-mono text-xs">
                    {facets.get(option.value)}
                  </span>}
              </CommandItem>;
        })}
        </CommandGroup>
        {selectedValues.size > 0 && <>
            <CommandSeparator />
            <CommandGroup>
              <CommandItem onSelect={handleClearFilters} className="cursor-pointer justify-center text-center text-sm">
                Clear filters
              </CommandItem>
            </CommandGroup>
          </>}
      </CommandList>
    </Command>;
}
interface ITableFacetedFilter<TData, TValue> {
  column?: Column<TData, TValue>;
  title?: string;
  options: {
    label: string;
    value: string;
    icon?: IIconNames;
  }[];
}
export function TableFacetedFilter<TData, TValue>({
  column,
  title,
  options
}: ITableFacetedFilter<TData, TValue>): JSX.Element {
  const selectedValues = useMemoDeepCompare(() => new Set(column?.getFilterValue() as string[]), [column]);
  return <Popover data-sentry-element="Popover" data-sentry-component="TableFacetedFilter" data-sentry-source-file="TableFacetedFilter.tsx">
      <PopoverTrigger asChild data-sentry-element="PopoverTrigger" data-sentry-source-file="TableFacetedFilter.tsx">
        <div className="flex h-8 cursor-pointer items-center rounded py-1 text-xs text-muted-foreground">
          <Icon name="plus-circle" className="mr-2" data-sentry-element="Icon" data-sentry-source-file="TableFacetedFilter.tsx" />
          {title}
          {selectedValues?.size > 0 && <>
              <Separator orientation="vertical" className="mx-2 h-4" />
              <Badge colorVariant="blue" className="rounded-sm px-1 font-normal lg:hidden">
                {selectedValues.size}
              </Badge>
              <div className="hidden space-x-1 lg:flex">
                {selectedValues.size > 2 ? <Badge colorVariant="blue" className="rounded-sm px-1 font-normal">
                    {selectedValues.size} selected
                  </Badge> : options.filter(option => selectedValues.has(option.value)).map(option => <Badge colorVariant="blue" key={option.value} className="rounded-sm px-1 font-normal">
                        {option.label}
                      </Badge>)}
              </div>
            </>}
        </div>
      </PopoverTrigger>
      <PopoverContent className="w-[200px] p-0" align="start" data-sentry-element="PopoverContent" data-sentry-source-file="TableFacetedFilter.tsx">
        <FilterCommand column={column} title={title} options={options} data-sentry-element="FilterCommand" data-sentry-source-file="TableFacetedFilter.tsx" />
      </PopoverContent>
    </Popover>;
}