"use client";

import { CommandLoading } from "cmdk";
import { type KeyboardEvent, useCallback, useRef, useState } from "react";
import { cn } from "../../lib/utils";
import { Command, CommandEmpty, CommandGroup, CommandInputAsTextInput, CommandItem, CommandList, CommandSeparator } from "./command";
import type { IIconNames } from "./icon";
import { Icon } from "./icon";
import { Skeleton } from "./skeleton";
export const AUTOCOMPETE_CREATE_ITEM_VALUE = "create_item";
type IAutoComplete<T extends string> = {
  className?: string;
  inputClassName?: string;
  groupHeading?: string;
  items: T[];
  itemRenderer?: (item: T) => React.ReactNode;
  emptyMessage: string;
  icon?: IIconNames;
  value?: T;
  onValueChange?: (value: T) => void;
  isLoading?: boolean;
  disabled?: boolean;
  placeholder?: string;
  filter?: (value: string, search: string) => number;
  createItem?: React.ReactNode;
  onCreateItem?: () => void;
  small?: boolean;
};
export const AutoComplete = <T extends string,>({
  className,
  inputClassName,
  groupHeading,
  items,
  itemRenderer,
  icon,
  placeholder,
  emptyMessage,
  value,
  onValueChange,
  disabled,
  filter,
  createItem,
  onCreateItem,
  isLoading = false,
  small = false
}: IAutoComplete<T>): JSX.Element => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isOpen, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState<string>(value ?? "");
  const handleKeyDown = useCallback((event: KeyboardEvent<HTMLDivElement>) => {
    const input = inputRef.current;
    if (!input) {
      return;
    }

    // Keep the items displayed when the user is typing
    if (!isOpen) {
      setOpen(true);
    }

    // This is not a default behaviour of the <input /> field
    if (event.key === "Enter" && input.value !== "") {
      const optionToSelect = items.find(option => option === input.value);
      if (optionToSelect !== undefined) {
        onValueChange?.(optionToSelect);
      }
    }
    if (event.key === "Escape") {
      input.blur();
    }
  }, [isOpen, items, onValueChange]);
  const handleBlur = useCallback(() => {
    setOpen(false);
  }, []);
  const handleSelect = useCallback((selectedOption: T) => (): void => {
    onValueChange?.(selectedOption);

    // This is a hack to prevent the input from being focused after the user selects an option
    // We can call this hack: "The next tick"
    setTimeout(() => {
      inputRef?.current?.blur();
    }, 0);
  }, [onValueChange]);
  const handleCreateSelect = useCallback(() => {
    onCreateItem?.();

    // This is a hack to prevent the input from being focused after the user selects an option
    // We can call this hack: "The next tick"
    setTimeout(() => {
      inputRef?.current?.blur();
    }, 0);
  }, [onCreateItem]);
  const handleValueChange = useCallback((v: string) => {
    if (isLoading === true) {
      return;
    }
    setInputValue(v);
  }, [isLoading, setInputValue]);
  const handleFocus = useCallback(() => {
    setOpen(true);
  }, []);
  const handleMouseDown = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
  }, []);
  return <Command onKeyDown={handleKeyDown} filter={filter} className={cn(className, "overflow-visible")} data-sentry-element="Command" data-sentry-component="AutoComplete" data-sentry-source-file="autocomplete.tsx">
      <CommandInputAsTextInput className={inputClassName} ref={inputRef} icon={icon} value={inputValue} onValueChange={handleValueChange} onBlur={handleBlur} onFocus={handleFocus} placeholder={placeholder} disabled={disabled} small={small} data-sentry-element="CommandInputAsTextInput" data-sentry-source-file="autocomplete.tsx" />
      <div className="relative">
        {isOpen ? <div className="absolute top-0 z-10 mt-1 w-full rounded bg-muted outline-none">
            <CommandList className="rounded-lg ring-1 ring-border">
              <CommandEmpty className="select-none rounded-sm px-2 py-3 text-center text-sm">
                {emptyMessage}
              </CommandEmpty>
              {isLoading ? <CommandLoading>
                  <div className="p-1">
                    <Skeleton>Property Name</Skeleton>
                  </div>
                </CommandLoading> : null}
              <CommandGroup heading={groupHeading}>
                {items.map(option => {
              const isSelected = value === option;
              return <CommandItem key={option} value={option} onSelect={handleSelect(option)} onMouseDown={handleMouseDown} className={cn("flex w-full items-center gap-2", !isSelected && !itemRenderer ? "pl-8" : null)}>
                      {isSelected && !itemRenderer ? <Icon name="check" className="w-4" /> : null}
                      {itemRenderer ? itemRenderer(option) : option}
                    </CommandItem>;
            })}
              </CommandGroup>
              {createItem != null && <>
                  <CommandSeparator />
                  <CommandGroup>
                    <CommandItem onSelect={handleCreateSelect} onMouseDown={handleMouseDown} forceMount value={AUTOCOMPETE_CREATE_ITEM_VALUE}>
                      {createItem}
                    </CommandItem>
                  </CommandGroup>
                </>}
            </CommandList>
          </div> : null}
      </div>
    </Command>;
};