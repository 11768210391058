import { map, reduce } from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useClickAway } from "react-use";
import { useFocusWithin } from "../../hooks/useFocusWithin";
import { cn } from "../../lib/utils";
import { Button } from "../atoms/button";
import { Command, CommandGroup, CommandInputAsTextInput, CommandItem, CommandList } from "../atoms/command";
import type { IIconNames } from "../atoms/icon";
import { Icon } from "../atoms/icon";
import { Spinner } from "../atoms/spinner";
type IMagicInputItemIcon = {
  type: "icon";
  icon: IIconNames;
} | {
  type: "component";
  icon: React.ReactNode;
};
export type IMagicInputItem = {
  category: string;
  iconLeft?: IMagicInputItemIcon;
  iconRight?: IMagicInputItemIcon;
  title: string;
  cta: {
    title: string;
    icon: IIconNames;
    onClick: () => void;
  };
};
export type IMagicInputSuggestion = ({
  isLoading: false;
} & IMagicInputItem) | {
  isLoading: true;
  category: string;
  title: string;
};
const DEFAULT_CATEGORY = "default";
interface IMagicInput {
  inputRef: React.Ref<HTMLInputElement>;
  onChange?: (search: string) => void;
  className?: string;
  value: string;
  suggestion?: IMagicInputSuggestion;
  items?: IMagicInputItem[];
  placeholder: string;
  isLoading?: boolean;
  onSubmit?: () => void;
  onSuggestion?: () => void;
  small?: boolean;
  subtle?: boolean;
  showCategories?: boolean;
}
export const MagicInput: React.FC<IMagicInput> = ({
  className,
  inputRef,
  value,
  placeholder,
  isLoading,
  onChange,
  onSubmit,
  onSuggestion,
  items,
  small,
  subtle,
  suggestion,
  showCategories = true
}) => {
  const commandRef = useRef<HTMLDivElement>(null);
  const {
    hasFocus,
    resetFocus
  } = useFocusWithin(commandRef);
  const [isOpen, setIsOpen] = useState(false);
  useClickAway(commandRef, () => {
    setIsOpen(false);
    resetFocus();
  });
  useEffect(() => {
    if (hasFocus === true) {
      setIsOpen(true);
    }
  }, [hasFocus]);
  const handleSelect = useCallback((item: IMagicInputItem): void => {
    setIsOpen(false);
    resetFocus();
    item.cta.onClick();
  }, [resetFocus]);
  const handleSuggestionSelect = useCallback((item: IMagicInputSuggestion): void => {
    setIsOpen(false);
    resetFocus();
    if (item.isLoading === false) {
      item.cta.onClick();
    }
  }, [resetFocus]);
  const handleChange = useCallback((search: string) => {
    onChange?.(search);
  }, [onChange]);
  const handleSubmit = useCallback(() => {
    onSubmit?.();
  }, [onSubmit]);
  const handleSuggestion = useCallback(() => {
    onSuggestion?.();
  }, [onSuggestion]);
  const getItems = (): React.ReactNode => {
    if (items == null || items.length === 0) {
      return null;
    }
    const itemsByCategory = reduce(items, (acc, item) => {
      const category = item.category ?? DEFAULT_CATEGORY;
      acc[category] ??= [];
      acc[category]?.push(item);
      return acc;
    }, {} as Record<string, IMagicInputItem[]>);
    if (showCategories === false) {
      return iterateItems(items, handleSelect);
    }
    return map(itemsByCategory, (categoryItems, category) => {
      return <CommandGroup key={category} heading={category}>
          {iterateItems(categoryItems, handleSelect)}
        </CommandGroup>;
    });
  };
  return <Command className={cn("relative overflow-visible", subtle !== true && "border-magic", className)} ref={commandRef} data-sentry-element="Command" data-sentry-component="MagicInput" data-sentry-source-file="magic-input.tsx">
      <div className={cn("relative flex w-full items-center")}>
        <CommandInputAsTextInput value={value} placeholder={placeholder} autoComplete="off" disabled={isLoading} ref={inputRef} onValueChange={handleChange} icon={undefined} small={small} data-sentry-element="CommandInputAsTextInput" data-sentry-source-file="magic-input.tsx" />
        {onSuggestion == null ? <Button type="submit" variant={"ghost"} className={cn("absolute right-1.5 top-1/2 !h-8 -translate-y-1/2", small === true && "right-1 top-1/2 !h-6 -translate-y-1/2", subtle !== true && "text-purple-500 hover:!bg-purple-100 focus:!bg-purple-100 focus-visible:!ring-purple-300 active:!ring-purple-300")} size={small !== true ? "lg" : "xs"} data-dd-action-name="Submit" isLoading={isLoading} disabled={isLoading} onClick={handleSubmit} iconLeft="chevron-right" /> : <Button type="button" variant="ghost" className={cn("absolute right-1.5 top-1/2 !h-8 -translate-y-1/2 hover:!bg-purple-50 focus:!bg-purple-50 focus-visible:!ring-purple-300 active:!ring-purple-300", small === true && "right-1 top-1/2 !h-6 -translate-y-1/2")} size={small !== true ? "lg" : "xs"} data-dd-action-name="Generate Suggestion" isLoading={isLoading} disabled={isLoading} onClick={handleSuggestion}>
            {isLoading === true ? <Spinner className="mt-1" /> : <Icon name="sparkles" className={cn(subtle !== true && "text-purple-500")} />}
          </Button>}
      </div>

      {isOpen && (suggestion != null || items != null && items.length > 0) ? <div className="relative">
          <div className="absolute top-0 z-10 mt-1 w-full rounded-lg bg-muted outline-none ring-1 ring-border">
            <CommandList>
              {suggestion ? <CommandGroup key="suggestions" heading="Suggestion">
                  <MagicInputSuggestion suggestion={suggestion} value={value} key={suggestion.title} onSelect={handleSuggestionSelect} />
                </CommandGroup> : null}
              {getItems()}
            </CommandList>
          </div>
        </div> : null}
    </Command>;
};
const MagicInputSuggestion: React.FC<{
  suggestion: IMagicInputSuggestion;
  value: string;
  onSelect: (item: IMagicInputSuggestion) => void;
}> = ({
  suggestion,
  value,
  onSelect
}) => {
  const handleMouseDown = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
  }, []);
  const handleSelect = useCallback(() => {
    onSelect(suggestion);
  }, [onSelect, suggestion]);
  const contents = useMemo(() => {
    if (suggestion.isLoading === true) {
      return <div className="flex shrink items-center space-x-2">
          <Spinner />
          <div className="truncate text-nowrap">{suggestion.title}</div>
        </div>;
    }
    return <>
        <div className="flex w-full shrink items-center justify-between">
          <div className="flex items-center space-x-2 overflow-hidden">
            {suggestion.iconLeft?.type === "icon" && <Icon name={suggestion.iconLeft.icon} className="size-3 shrink-0" />}
            {suggestion.iconLeft?.type === "component" && <div className="shrink-0">{suggestion.iconLeft.icon}</div>}
            {suggestion.title != null && <div className="truncate">{suggestion.title}</div>}
          </div>
          <div className="flex shrink-0 items-center space-x-2">
            {suggestion.iconRight?.type === "icon" && <Icon name={suggestion.iconRight.icon} className="size-3" />}
            {suggestion.iconRight?.type === "component" && suggestion.iconRight.icon}
          </div>
        </div>
        <Icon name={suggestion.cta.icon} />
      </>;
  }, [suggestion]);
  return <CommandItem className="flex w-full cursor-pointer items-center justify-between text-sm text-purple-500 aria-selected:bg-purple-100 aria-selected:text-purple-600" value={value} onSelect={handleSelect} onMouseDown={handleMouseDown} data-sentry-element="CommandItem" data-sentry-component="MagicInputSuggestion" data-sentry-source-file="magic-input.tsx">
      {contents}
    </CommandItem>;
};
const MagicInputListItem: React.FC<{
  item: IMagicInputItem;
  onSelect: (item: IMagicInputItem) => void;
}> = ({
  item,
  onSelect
}) => {
  const handleMouseDown = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
  }, []);
  const handleSelect = useCallback(() => {
    onSelect(item);
  }, [onSelect, item]);
  return <CommandItem className="flex w-full cursor-pointer items-center justify-between text-sm" value={item.title} onSelect={handleSelect} onMouseDown={handleMouseDown} data-sentry-element="CommandItem" data-sentry-component="MagicInputListItem" data-sentry-source-file="magic-input.tsx">
      <div className="flex w-full items-center">
        <div className="mr-2 shrink-0">
          {item.iconLeft?.type === "icon" && <Icon name={item.iconLeft.icon} className="size-3" />}
          {item.iconLeft?.type === "component" && item.iconLeft.icon}
        </div>
        <div className="grow truncate">{item.title != null && <div className="truncate">{item.title}</div>}</div>
        <div className="ml-2 shrink-0">
          {item.iconRight?.type === "icon" && <Icon name={item.iconRight.icon} className="size-3" />}
          {item.iconRight?.type === "component" && item.iconRight.icon}
        </div>
      </div>
      <Icon name={item.cta.icon} data-sentry-element="Icon" data-sentry-source-file="magic-input.tsx" />
    </CommandItem>;
};
const iterateItems = (categoryItems: IMagicInputItem[], onSelect: (item: IMagicInputItem) => void): React.ReactNode => {
  const handleSelect = onSelect;
  return categoryItems.map((item, idx) => {
    return <MagicInputListItem key={idx} item={item} onSelect={handleSelect} />;
  });
};