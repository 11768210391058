"use client";

import { AnimatePresence, motion } from "framer-motion";
import * as React from "react";
import { cn } from "../../lib/utils";
interface ISidebarProps {
  className?: string;
  children: React.ReactNode;
}
export const SidebarContainer: React.FC<ISidebarProps> = ({
  children,
  className
}) => {
  return <AnimatePresence data-sentry-element="AnimatePresence" data-sentry-component="SidebarContainer" data-sentry-source-file="sidebar-container.tsx">
      <motion.div key="sidebar" initial={{
      x: "100%"
    }} animate={{
      x: 0
    }} exit={{
      x: "100%"
    }} transition={{
      type: "tween"
    }} className={cn("shadow-uniform-lg absolute inset-0 z-30 flex h-full w-full shrink-0 grow-0 overflow-hidden", "sm:inset-2 sm:left-auto sm:h-[calc(100%-1rem)] sm:w-fit sm:min-w-[600px] sm:max-w-full sm:rounded-xl", className)} data-sentry-element="unknown" data-sentry-source-file="sidebar-container.tsx">
        <div className="w-full sm:z-40 sm:justify-start sm:overflow-auto">
          <div className="flex size-full grow flex-col bg-gray-50 p-2 sm:bg-paper sm:p-0">{children}</div>
        </div>
      </motion.div>
    </AnimatePresence>;
};