import React from "react";
import { cn } from "../../lib/utils";
import { Skeleton } from "./skeleton";
export interface ITableSkeleton {
  className?: string;
  numberCols: number;
  numberRows: number;
  density: "compact" | "default" | "airy";
}
const randomLookingLcg = (row: number, col: number, a = 11, c = 23, m = 5): number => {
  if (row % m === 0) {
    return (col * a + c) % m / m;
  }
  if (col % m === 0) {
    return (row * a + c) % m / m;
  }
  return (row * col * a + c) % m / m;
};
export const TableSkeleton: React.FC<ITableSkeleton> = ({
  className,
  numberCols,
  numberRows,
  density
}) => {
  const densityClass = {
    compact: "p-1",
    default: "p-2",
    airy: "p-3"
  }[density];
  return <div className={cn("w-full", className, "border-border pointer-events-none overflow-hidden rounded border")} data-sentry-component="TableSkeleton" data-sentry-source-file="table-skeleton.tsx">
      <table className="w-full border-collapse">
        <thead>
          <tr className="h-[42px] border-b border-border bg-muted-background">
            {Array.from({
            length: numberCols
          }).map((_, index) => <th key={index} className={cn(densityClass, "text-left")}>
                <Skeleton className="h-3" style={{
              width: 50 + 100 * randomLookingLcg(20, index + 1)
            }} />
              </th>)}
          </tr>
        </thead>
        <tbody>
          {Array.from({
          length: numberRows
        }).map((_, rowIndex) => <tr key={rowIndex} className="h-[36px]">
              {Array.from({
            length: numberCols
          }).map((__, colIndex) => <td key={colIndex} className={cn(densityClass)}>
                  <Skeleton className="h-4" style={{
              width: 50 + 100 * randomLookingLcg(rowIndex + 1, colIndex + 1)
            }} />
                </td>)}
            </tr>)}
        </tbody>
      </table>
    </div>;
};