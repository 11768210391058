import React from "react";
import { AbstractDashboardLayout } from "./AbstractDashboardLayout";
import type { IBreadcrumbNavItem } from "./api";
export interface IPlaybookViewLayout {
  breadcrumbs: IBreadcrumbNavItem[];
  moduleContent: React.ReactNode;
  actions?: React.ReactNode;
}
export const PlaybookViewLayout: React.FC<IPlaybookViewLayout> = ({
  breadcrumbs,
  moduleContent,
  actions
}) => {
  const mainPanel = <div className="flex h-[calc(100vh_-_3.5rem)] flex-col @container">
      <div className="flex h-0 grow flex-col overflow-hidden">{moduleContent}</div>
    </div>;
  return <div className="flex grow flex-col bg-gray-100" data-sentry-component="PlaybookViewLayout" data-sentry-source-file="PlaybookViewLayout.tsx">
      <AbstractDashboardLayout breadcrumbs={breadcrumbs} mainPanel={mainPanel} actions={actions} data-sentry-element="AbstractDashboardLayout" data-sentry-source-file="PlaybookViewLayout.tsx" />
    </div>;
};