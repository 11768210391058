"use client";

import * as ProgressPrimitive from "@radix-ui/react-progress";
import * as React from "react";
import { cn } from "../../lib/utils";
const Progress = React.forwardRef<React.ElementRef<typeof ProgressPrimitive.Root>, React.ComponentPropsWithoutRef<typeof ProgressPrimitive.Root>>(({
  className,
  value,
  ...props
}, ref) => <ProgressPrimitive.Root ref={ref} className={cn("bg-secondary relative h-4 w-full overflow-hidden rounded-sm", className)} {...props}>
    <ProgressPrimitive.Indicator className="size-full flex-1 bg-primary transition-all" style={{
    transform: `translateX(-${100 - (value ?? 0)}%)`
  }} />
  </ProgressPrimitive.Root>);
Progress.displayName = ProgressPrimitive.Root.displayName;
export { Progress };