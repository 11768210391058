import React, { useCallback, useState } from "react";
import { cn } from "../../lib/utils";
import { Button } from "../atoms/button";
export interface IWorkspaceLayout {
  className?: string;
  sidebarHeader?: React.ReactNode;
  sidebarFooter?: React.ReactNode;
  sidebarContent: React.ReactNode;
  mainPanel: React.ReactNode;
}
export const WorkspaceLayout: React.FC<IWorkspaceLayout> = ({
  className,
  sidebarHeader,
  sidebarFooter,
  sidebarContent,
  mainPanel
}) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const handleOpenChange = useCallback((isOpen: boolean): void => {
    setIsSidebarOpen(isOpen);
  }, []);
  const handleToggleSidebar = useCallback(() => {
    handleOpenChange(!isSidebarOpen);
  }, [handleOpenChange, isSidebarOpen]);
  return <main className={cn("text-ink flex h-[100vh] w-[100vw] flex-row overflow-hidden", className)} data-sentry-component="WorkspaceLayout" data-sentry-source-file="WorkspaceLayout.tsx">
      <div className={cn("fixed inset-y-0 left-0 z-40 flex w-full transform flex-col items-stretch bg-gray-50 transition-all sm:relative sm:z-10 sm:w-64 sm:min-w-64 sm:translate-x-0", isSidebarOpen ? "translate-x-0" : "-translate-x-full")}>
        {sidebarHeader}
        <div className="shrink-0 grow">{sidebarContent}</div>
        {sidebarFooter}
      </div>

      <div className="z-30 max-h-full w-full max-w-full shrink grow space-y-1 bg-gray-50 p-2 @container sm:pl-0">
        <Button onClick={handleToggleSidebar} iconLeft="chevrons-left" title="See all apps" variant="ghost" className="sm:hidden" data-sentry-element="Button" data-sentry-source-file="WorkspaceLayout.tsx">
          Back
        </Button>
        <div className="flex size-full overflow-hidden rounded-xl bg-paper">{mainPanel}</div>
      </div>
    </main>;
};