"use client";

import { PopoverPortal } from "@radix-ui/react-popover";
import { format } from "date-fns";
import * as React from "react";
import { useCallback } from "react";
import { cn } from "../../lib/utils";
import { Calendar } from "./calendar";
import { Icon } from "./icon";
import { inputVariants } from "./input";
import { Popover, PopoverContent, PopoverTrigger } from "./popover";
interface IDateInput {
  className?: string;
  value: Date | undefined;
  placeholder?: string;
  onChange: (date: Date | undefined) => void;
  onBlur?: () => void;
  onFocus?: () => void;
  small?: boolean;
  variant?: "button" | "text";
  isOpen?: boolean;
  onOpenChange?: (open: boolean) => void;
  disabled?: boolean;
  closeOnSelect?: boolean;
}
interface IDateDisplay extends Omit<IDateInput, "onChange" | "isOpen" | "onOpenChange" | "closeOnSelect"> {
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  ref?: React.Ref<HTMLButtonElement | HTMLSpanElement>;
}
export const DateDisplay = React.forwardRef<HTMLButtonElement, IDateDisplay>(({
  className,
  value,
  placeholder,
  onBlur,
  onFocus,
  small,
  variant = "button",
  disabled,
  onClick
}, ref): React.ReactElement => {
  const handleBlur = useCallback(() => onBlur?.(), [onBlur]);
  const handleFocus = useCallback(() => onFocus?.(), [onFocus]);
  const handleClick = useCallback((e: React.MouseEvent<HTMLButtonElement>) => onClick?.(e), [onClick]);
  const content = <>
        {variant === "button" && <Icon name="calendar" className="mr-3 size-4 shrink-0" />}
        <span className={cn("truncate", value ? "text-ink" : "text-placeholder")}>
          {value != null ? format(value, "PPP") : placeholder ?? "Select a date..."}
        </span>
      </>;
  if (variant === "text") {
    return <button type="button" role="button" className={cn("flex h-full items-center truncate", className, small === true ? "text-base" : "text-lg")} onClick={handleClick} onBlur={handleBlur} onFocus={handleFocus} ref={ref}>
          {content}
        </button>;
  }
  return <button className={cn(inputVariants({
    size: small === true ? "small" : "default"
  }), "flex items-center justify-start overflow-hidden", className)} type="button" role="button" onBlur={handleBlur} onFocus={handleFocus} ref={ref as React.Ref<HTMLButtonElement>} disabled={disabled} onClick={handleClick}>
        {content}
      </button>;
});
DateDisplay.displayName = "DateDisplay";
export const DateInput = React.forwardRef<HTMLButtonElement, IDateInput>(({
  className,
  value,
  placeholder,
  onChange,
  onBlur,
  onFocus,
  small,
  variant = "button",
  isOpen,
  onOpenChange,
  disabled,
  closeOnSelect = true
}, ref): React.ReactElement => {
  const handleDateSelect = useCallback((date: Date | undefined) => {
    onChange(date);
    if (closeOnSelect) {
      onOpenChange?.(false);
    }
  }, [onChange, closeOnSelect, onOpenChange]);
  const handleOpenChange = useCallback((open: boolean) => onOpenChange?.(open), [onOpenChange]);
  const handleBlur = useCallback(() => onBlur?.(), [onBlur]);
  const handleFocus = useCallback(() => onFocus?.(), [onFocus]);
  return <Popover open={isOpen} onOpenChange={handleOpenChange}>
        <PopoverTrigger asChild>
          <DateDisplay className={cn("w-full", className)} value={value} placeholder={placeholder} onBlur={handleBlur} onFocus={handleFocus} small={small} variant={variant} ref={ref} disabled={disabled} />
        </PopoverTrigger>
        <PopoverPortal>
          <PopoverContent className="w-auto p-0" align="start">
            <Calendar mode="single" selected={value} onSelect={handleDateSelect} defaultMonth={value} initialFocus={true} />
          </PopoverContent>
        </PopoverPortal>
      </Popover>;
});
DateInput.displayName = "DateInput";