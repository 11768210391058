import React from "react";
import { cn } from "../../../lib/utils";
import { Card } from "../../atoms/card";
import { GroupTableSkeleton } from "../../atoms/grouped-table-skeleton";
import { Separator } from "../../atoms/separator";
import { Skeleton } from "../../atoms/skeleton";
export interface IDataViewAppLayoutSkeleton {
  className?: string;
}
export const DataViewAppLayoutSkeleton: React.FC<IDataViewAppLayoutSkeleton> = ({
  className
}) => {
  return <div className={cn("flex h-full w-full grow flex-col gap-y-8 overflow-auto px-8 pt-8", className)} data-sentry-component="DataViewAppLayoutSkeleton" data-sentry-source-file="DataViewAppLayoutSkeleton.tsx">
      {/* Header */}
      <div className="flex w-full flex-row justify-between">
        <div className="flex flex-col gap-y-4">
          <Skeleton className="h-10 w-52 text-5xl font-bold" data-sentry-element="Skeleton" data-sentry-source-file="DataViewAppLayoutSkeleton.tsx" />
          <Skeleton className="h-6 w-20" data-sentry-element="Skeleton" data-sentry-source-file="DataViewAppLayoutSkeleton.tsx" />
        </div>
        <Skeleton className="h-8 w-24" data-sentry-element="Skeleton" data-sentry-source-file="DataViewAppLayoutSkeleton.tsx" />
      </div>
      {/* Metrics card */}
      <Card data-sentry-element="Card" data-sentry-source-file="DataViewAppLayoutSkeleton.tsx">
        <div className="flex p-4">
          <Skeleton className="h-4 grow" data-sentry-element="Skeleton" data-sentry-source-file="DataViewAppLayoutSkeleton.tsx" />
        </div>
        <Separator data-sentry-element="Separator" data-sentry-source-file="DataViewAppLayoutSkeleton.tsx" />
        <div className="flex flex-row gap-x-4 p-4">
          <Skeleton className="h-8 w-24" data-sentry-element="Skeleton" data-sentry-source-file="DataViewAppLayoutSkeleton.tsx" />
          <Skeleton className="h-8 w-32" data-sentry-element="Skeleton" data-sentry-source-file="DataViewAppLayoutSkeleton.tsx" />
        </div>
      </Card>
      {/* Main view skeleton */}
      <GroupTableSkeleton data-sentry-element="GroupTableSkeleton" data-sentry-source-file="DataViewAppLayoutSkeleton.tsx" />
    </div>;
};