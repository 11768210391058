import React from "react";
import { cn } from "../../lib/utils";
import { EntityStateColor } from "./entity-state-color-indicator";
import { Skeleton } from "./skeleton";
import type { ITableSkeleton } from "./table-skeleton";
import { TableSkeleton } from "./table-skeleton";
const fakeGroups = [{
  groupValue: "—————————",
  title: "—————————",
  numberRows: 4,
  numberCols: 12
}, {
  groupValue: "—————————————————————",
  title: "—————————————————————",
  numberRows: 2,
  numberCols: 12
}, {
  groupValue: "———————",
  title: "———————",
  numberRows: 6,
  numberCols: 12
}];
interface IGroupTableSkeleton {
  className?: string;
  density?: ITableSkeleton["density"];
}
export const GroupTableSkeleton: React.FC<IGroupTableSkeleton> = ({
  className,
  density
}) => {
  return <div className={cn("flex flex-col gap-8", className)} data-sentry-component="GroupTableSkeleton" data-sentry-source-file="grouped-table-skeleton.tsx">
      {fakeGroups.map(({
      numberRows,
      numberCols,
      groupValue
    }) => {
      return <div key={groupValue} className="w-full">
            <div className="mb-4 flex items-center gap-x-2">
              <EntityStateColor color={"neutral"} />
              <Skeleton>{groupValue}</Skeleton>
            </div>
            <TableSkeleton className={className} numberCols={numberCols} numberRows={numberRows} density={density ?? "default"} />
          </div>;
    })}
    </div>;
};