import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "@radix-ui/react-dropdown-menu";
import * as React from "react";
import { cn, getValidChildren } from "../../lib/utils";
import { Icon } from "./icon";
export interface IBreadcrumbProps extends React.ComponentPropsWithoutRef<"nav"> {
  /* The visual separator between each breadcrumb item */
  separator?: React.ReactElement;
  /**
   * If `true`, adds a separator between each breadcrumb item.
   * @default true
   */
  addSeparator?: boolean;
  /**
   * Index for the breadcrumb item before which collapsing should start.
   */
  beforeCollapseIndex?: number;
  /**
   * Index for the breadcrumb item after which collapsing should end.
   */
  afterCollapseIndex?: number;
}
export const Breadcrumb = React.forwardRef<HTMLElement, IBreadcrumbProps>(({
  children,
  className,
  separator = <Icon name="chevron-right" />,
  addSeparator = true,
  beforeCollapseIndex,
  afterCollapseIndex,
  ...props
}, forwardedRef) => {
  const validChildren = getValidChildren(children);
  if (beforeCollapseIndex != null && afterCollapseIndex != null && beforeCollapseIndex + afterCollapseIndex < validChildren.length) {
    return <nav className={cn("relative break-words", className)} aria-label="breadcrumb" {...props} ref={forwardedRef}>
          <ol className="flex items-center">
            {validChildren.map((child, index) => {
          if (index === beforeCollapseIndex - 1) {
            return <div key={index} className="flex">
                    {React.cloneElement(child, {
                addSeparator,
                separator,
                isLastChild: false
              } as React.Attributes)}
                    <div className="flex flex-row items-center">
                      <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                          <div className="shrink cursor-pointer bg-accent px-1 py-0 hover:bg-gray-200">
                            <Icon name="more-horizontal" />
                          </div>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent className="z-10 mt-2 space-y-2 border bg-paper px-6 py-4">
                          {validChildren.slice(beforeCollapseIndex, validChildren.length - afterCollapseIndex).map((item, idx) => <DropdownMenuItem key={idx}>{React.cloneElement(item)}</DropdownMenuItem>)}
                        </DropdownMenuContent>
                      </DropdownMenu>
                    </div>
                    <div className="mx-2">{separator}</div>
                  </div>;
          } else if (index > validChildren.length - afterCollapseIndex - 1 || index < beforeCollapseIndex) {
            return React.cloneElement(child, {
              addSeparator: index < validChildren.length && addSeparator,
              separator,
              isLastChild: validChildren.length === index + 1
            } as React.Attributes);
          }
        })}
          </ol>
        </nav>;
  }
  return <nav className={cn("relative break-words", className)} aria-label="breadcrumb" {...props} ref={forwardedRef}>
        <ol className="flex items-center">
          {validChildren.map((child, index) => React.cloneElement(child, {
        addSeparator,
        separator,
        isLastChild: validChildren.length === index + 1
      } as React.Attributes))}
        </ol>
      </nav>;
});
Breadcrumb.displayName = "Breadcrumb";
export interface IBreadcrumbItemProps extends IBreadcrumbProps {
  /**
   * If `true`, indicates that the breadcrumb item is active, adds
   * `aria-current=page` and renders a `span`
   */
  isCurrentPage?: boolean;
  isLastChild?: boolean;
}
export const BreadcrumbItem = React.forwardRef<HTMLLIElement, IBreadcrumbItemProps>(({
  children,
  className,
  isCurrentPage,
  isLastChild,
  separator,
  addSeparator,
  ...props
}, forwardedRef) => {
  const validChildren = getValidChildren(children);
  const clones = validChildren.map(child => {
    if (child.type === BreadcrumbLink) {
      return React.cloneElement(child, {
        isCurrentPage
      } as React.Attributes);
    }
    if (child.type === BreadcrumbSeparator) {
      return React.cloneElement(child, {
        // Just disable everything, I suppose?
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
        children: separator ?? (child.props as any).children
      } as React.Attributes);
    }
    return child;
  });
  return <li className={cn("inline-flex items-center", className)} {...props} ref={forwardedRef}>
        {clones}
        {!(isLastChild ?? false) && (addSeparator ?? false) ? <BreadcrumbSeparator>{separator}</BreadcrumbSeparator> : null}
      </li>;
});
BreadcrumbItem.displayName = "BreadcrumbItem";
export interface IBreadcrumbLinkProps extends React.ComponentPropsWithoutRef<"a">, Pick<IBreadcrumbItemProps, "isCurrentPage"> {
  as?: React.ElementType;
}
export const BreadcrumbLink = React.forwardRef<HTMLAnchorElement, IBreadcrumbLinkProps>(({
  className,
  as: asComp = "span",
  isCurrentPage,
  ...props
}, forwardedRef) => {
  const Comp: React.ElementType = isCurrentPage === true ? "span" : asComp ?? "a";
  return <Comp className={cn("text-muted-foreground [&:not([aria-current])]:hover:text-ink aria-[current]:text-ink cursor-pointer underline-offset-4", className)} aria-current={isCurrentPage === true ? "page" : undefined} {...props} ref={forwardedRef} />;
});
BreadcrumbLink.displayName = "BreadcrumbLink";
export type IBreadcrumbSeparatorProps = React.ComponentPropsWithoutRef<"span">;
export const BreadcrumbSeparator = React.forwardRef<HTMLSpanElement, IBreadcrumbSeparatorProps>(({
  className,
  ...props
}, forwardedRef) => {
  return <span className={cn("mx-1 opacity-40", className)} role="presentation" {...props} ref={forwardedRef} />;
});
BreadcrumbSeparator.displayName = "BreadcrumbSeparator";