import { range } from "lodash";
import { cn } from "../../lib/utils";
import { Skeleton } from "./skeleton";
export interface ITaskListProps {
  className?: string;
  tasks: Array<{
    text: string;
    status: "up-next" | "todo" | "done" | "failed";
  }>;
}
const SKELETON_WIDTHS = ["w-14", "w-20", "w-16", "w-20", "w-24", "w-16"];
export const TaskListSkeleton: React.FC<{
  className?: string;
}> = ({
  className
}) => {
  return <div className={cn("flex flex-col items-start gap-y-1 overflow-hidden", className)} data-sentry-component="TaskListSkeleton" data-sentry-source-file="task-list.tsx">
      {range(6).map(n => <div key={n} className="flex w-full flex-row items-center justify-start gap-x-2">
          <div className="size-4 shrink-0 text-nowrap rounded-full bg-skeleton" />
          <Skeleton className={cn(SKELETON_WIDTHS[n] ?? "w-16", "h-4 max-w-full")} />
        </div>)}
    </div>;
};
export const TaskList: React.FC<ITaskListProps> = ({
  tasks,
  className
}) => <div className={cn("flex flex-col items-start gap-y-1 overflow-hidden", className)} data-sentry-component="TaskList" data-sentry-source-file="task-list.tsx">
    {tasks.map((task, index) => <div key={`${task.text}-${index}`} className="flex w-full flex-row items-center justify-start gap-x-2">
        <div className={cn("border-box align-center text-primary flex h-4 w-4 shrink-0 select-none justify-center rounded-full", task.status === "done" && "bg-primary", task.status === "up-next" && "border-primary text-primary border-2 font-medium", task.status === "todo" && "border border-gray-400", task.status === "failed" && "bg-gray-400")}>
          {task.status === "done" && <span className="font-emoji text-[6pt] text-white [line-height:2]">✔️</span>}
          {task.status === "failed" && <span className="font-emoji text-[6pt] text-white [line-height:2]">❌</span>}
        </div>
        <div className={cn("shrink grow overflow-hidden text-ellipsis text-nowrap text-base", task.status === "up-next" && "text-primary text-nowrap")} title={task.text}>
          {task.text}
        </div>
      </div>)}
  </div>;