import React from "react";
import { cn } from "../../../lib/utils";
interface IHeader {
  className?: string;
  leftContent?: React.ReactNode;
  rightContent?: React.ReactNode;
}
export const Header: React.FC<IHeader> = ({
  className,
  leftContent,
  rightContent
}) => {
  return <div className={cn("theme-blue:bg-paper theme-blue:border-none bg-accent-background z-10 flex h-14 w-full items-center justify-between border-b px-4 py-4 transition-all duration-300 ease-in-out", className)} data-sentry-component="Header" data-sentry-source-file="Header.tsx">
      <div className="flex items-center space-x-4">{leftContent}</div>
      {rightContent}
    </div>;
};