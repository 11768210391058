"use client";

import * as AccordionPrimitive from "@radix-ui/react-accordion";
import * as React from "react";
import { cn } from "../../lib/utils";
import { Icon } from "./icon";
const Accordion = AccordionPrimitive.Root;
const AccordionItem = React.forwardRef<React.ElementRef<typeof AccordionPrimitive.Item>, React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Item>>(({
  className,
  ...props
}, ref) => <AccordionPrimitive.Item ref={ref} className={className} {...props} />);
AccordionItem.displayName = "AccordionItem";
const AccordionTrigger = React.forwardRef<React.ElementRef<typeof AccordionPrimitive.Trigger>, React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Trigger>>(({
  className,
  children,
  ...props
}, ref) => <AccordionPrimitive.Header className="flex">
    <AccordionPrimitive.Trigger ref={ref} className={cn("flex flex-1 items-center justify-start space-x-1 py-1 text-lg font-medium transition-all hover:underline [&[data-state=open]>svg]:rotate-90", className)} {...props}>
      <Icon name="chevron-right" className="size-3 shrink-0 transition-transform duration-200" />
      <span>{children}</span>
    </AccordionPrimitive.Trigger>
  </AccordionPrimitive.Header>);
AccordionTrigger.displayName = AccordionPrimitive.Trigger.displayName;
const AccordionContent = React.forwardRef<React.ElementRef<typeof AccordionPrimitive.Content>, React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Content>>(({
  className,
  children,
  ...props
}, ref) => <AccordionPrimitive.Content ref={ref} className={cn("data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down text-muted-foreground ml-4 overflow-hidden text-base transition-all", className)} {...props}>
    <div className="pb-4 pt-0">{children}</div>
  </AccordionPrimitive.Content>);
AccordionContent.displayName = AccordionPrimitive.Content.displayName;
export { Accordion, AccordionContent, AccordionItem, AccordionTrigger };