import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";
import type { ClassValue } from "clsx";
import * as React from "react";
import { cn } from "../../lib/utils";
import type { IIconNames } from "./icon";
import { Icon } from "./icon";
import { Spinner } from "./spinner";
const badgeVariants = cva("inline-flex items-center justify-center gap-x-1 rounded-lg border border-transparent px-2.5 py-0 font-normal tracking-wide text-black transition-colors focus:outline-none focus:ring-1 focus:ring-ring focus:ring-offset-2", {
  variants: {
    colorVariant: {
      lilac: "bg-pastel-lilac-background",
      purple: "bg-pastel-purple-background",
      pink: "bg-pastel-pink-background",
      red: "bg-pastel-red-background",
      brown: "bg-pastel-brown-background",
      orange: "bg-pastel-orange-background",
      yellow: "bg-pastel-yellow-background",
      lime: "bg-pastel-lime-background",
      sage: "bg-pastel-sage-background",
      green: "bg-pastel-green-background",
      emerald: "bg-pastel-emerald-background",
      teal: "bg-pastel-teal-background",
      blue: "bg-pastel-blue-background",
      transparent: "bg-paper",
      white: "bg-white",
      lightGray: "bg-gray-100",
      gray: "bg-gray-200",
      primary: "bg-primary-background text-white",
      secondary: "bg-secondary-background",
      dark: "bg-ink text-paper"
    },
    variant: {
      outline: "border border-ink",
      ghost: ""
    },
    size: {
      sm: "h-5 px-1.5 text-xs",
      md: "h-6 px-2 text-sm",
      lg: "h-7 px-2.5 text-base"
    }
  },
  compoundVariants: [{
    variant: "outline",
    colorVariant: "lightGray",
    class: "border-gray-400 bg-transparent text-muted-foreground"
  }, {
    variant: "outline",
    colorVariant: "gray",
    class: "border-gray-700 bg-transparent text-gray-800"
  }, {
    variant: "outline",
    colorVariant: "blue",
    class: "border-brand-blue bg-transparent text-brand-blue"
  }, {
    variant: "outline",
    colorVariant: "purple",
    class: "border-purple-500 bg-transparent text-purple-500"
  }, {
    variant: "outline",
    colorVariant: "pink",
    class: "border-pink-500 bg-transparent text-pink-500"
  }, {
    variant: "outline",
    colorVariant: "red",
    class: "border-red-600 bg-transparent text-red-600"
  }, {
    variant: "outline",
    colorVariant: "brown",
    class: "border-yellow-900 bg-transparent text-yellow-900"
  }, {
    variant: "outline",
    colorVariant: "orange",
    class: "border-orange-600 bg-transparent text-orange-600"
  }, {
    variant: "outline",
    colorVariant: "yellow",
    class: "border-yellow-600 bg-transparent text-yellow-600"
  }, {
    variant: "outline",
    colorVariant: "green",
    class: "border-green-700 bg-transparent text-green-700"
  }, {
    variant: "outline",
    colorVariant: "transparent",
    class: "border-transparent bg-transparent text-ink"
  }, {
    variant: "outline",
    colorVariant: "lilac",
    class: "border-purple-400 bg-transparent text-purple-400"
  }, {
    variant: "outline",
    colorVariant: "lime",
    class: "border-lime-600 bg-transparent text-lime-600"
  }, {
    variant: "outline",
    colorVariant: "sage",
    class: "border-green-600 bg-transparent text-green-600"
  }, {
    variant: "outline",
    colorVariant: "emerald",
    class: "border-emerald-600 bg-transparent text-emerald-600"
  }, {
    variant: "outline",
    colorVariant: "teal",
    class: "border-teal-600 bg-transparent text-teal-600"
  }, {
    variant: "outline",
    colorVariant: "white",
    class: "border-gray-300 bg-transparent text-gray-800"
  }, {
    variant: "outline",
    colorVariant: "primary",
    class: "border-primary-background bg-transparent text-primary-background"
  }, {
    variant: "outline",
    colorVariant: "secondary",
    class: "border-secondary-foreground bg-transparent text-secondary-foreground"
  }, {
    variant: "outline",
    colorVariant: "dark",
    class: "border-black bg-transparent text-black"
  }],
  defaultVariants: {
    variant: "ghost",
    colorVariant: "lightGray",
    size: "md"
  }
});
export type IBadgeColorVariant = VariantProps<typeof badgeVariants>["colorVariant"];
export interface IBadgeProps extends React.HTMLAttributes<HTMLDivElement>, VariantProps<typeof badgeVariants> {
  asChild?: boolean;
  iconLeft?: IIconNames;
  iconRight?: IIconNames;
  isLoading?: boolean;
  interactive?: boolean;
}
function getSquareStyles(children: IBadgeProps["children"], iconLeft: IBadgeProps["iconLeft"], iconRight: IBadgeProps["iconRight"], size: IBadgeProps["size"]): ClassValue {
  if (children == null && (iconLeft != null || iconRight != null)) {
    return size === "sm" ? "w-4 px-1" : "w-6 px-1";
  } else {
    return "";
  }
}
const Badge = React.forwardRef<HTMLDivElement, IBadgeProps>(({
  className,
  variant,
  colorVariant,
  size,
  asChild = false,
  children,
  iconLeft,
  iconRight,
  isLoading,
  interactive,
  ...props
}, ref) => {
  const BadgeComponent = asChild ? Slot : "div";
  const iconClass = size === "lg" ? "h-4 w-4" : "h-3 w-3";
  const squareStyles = getSquareStyles(children, iconLeft, iconRight, size);
  const isColoredBackground = colorVariant !== "transparent" && colorVariant !== "white" && variant !== "outline";
  const isLightVariant = colorVariant !== "primary" && colorVariant !== "dark";
  const blendBurn = isColoredBackground && isLightVariant;
  const content = isLoading === true ? <Spinner /> : <div className={cn("flex items-center gap-x-1 overflow-hidden", blendBurn && "mix-blend-color-burn")}>
          {iconLeft ? <Icon name={iconLeft} className={iconClass} /> : null}
          {children != null && <span className="flex flex-1 items-center truncate">{children}</span>}
          {iconRight ? <Icon name={iconRight} className={iconClass} /> : null}
        </div>;
  return <BadgeComponent className={cn(badgeVariants({
    variant,
    colorVariant,
    size,
    className
  }), squareStyles, interactive === true && "hover:brightness-70 cursor-pointer", "max-w-full")} ref={ref} {...props}>
        {content}
      </BadgeComponent>;
});
Badge.displayName = "Badge";
export { Badge, badgeVariants };