import React, { useCallback, useEffect, useMemo, useState } from "react";
import { cn } from "../../lib/utils";
import { Input } from "./input";
import type { IPeriod, ITimePickerType } from "./time-picker-utils";
import { getArrowByType, getDateByType, setDateByType } from "./time-picker-utils";
export interface ITimePickerInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  picker: ITimePickerType;
  date: Date | undefined;
  onSetDate: (date: Date | undefined) => void;
  period?: IPeriod;
  onRightFocus?: () => void;
  onLeftFocus?: () => void;
}
const TimePickerInput = React.forwardRef<HTMLInputElement, ITimePickerInputProps>(({
  className,
  type = "tel",
  value,
  id,
  name,
  date = new Date(new Date().setHours(0, 0, 0, 0)),
  onSetDate,
  onChange,
  onKeyDown,
  picker,
  period,
  onLeftFocus,
  onRightFocus,
  ...props
}, ref) => {
  const [flag, setFlag] = useState<boolean>(false);
  const [prevIntKey, setPrevIntKey] = useState<string>("0");

  /**
   * allow the user to enter the second digit within 2 seconds
   * otherwise start again with entering first digit
   */
  useEffect(() => {
    if (flag) {
      const timer = setTimeout(() => {
        setFlag(false);
      }, 2000);
      return (): void => clearTimeout(timer);
    }
  }, [flag]);
  const calculatedValue = useMemo(() => {
    return getDateByType(date, picker);
  }, [date, picker]);
  const calculateNewValue = useCallback((key: string): string => {
    /*
     * If picker is '12hours' and the first digit is 0, then the second digit is automatically set to 1.
     * The second entered digit will break the condition and the value will be set to 10-12.
     */
    if (picker === "12hours") {
      if (flag && calculatedValue.slice(1, 2) === "1" && prevIntKey === "0") return "0" + key;
    }
    return !flag ? "0" + key : calculatedValue.slice(1, 2) + key;
  }, [flag, calculatedValue, picker, prevIntKey]);
  const handleKeyDown = useCallback((e: React.KeyboardEvent<HTMLInputElement>): void => {
    onKeyDown?.(e);
    if (e.key === "Tab") return;
    e.preventDefault();
    if (e.key === "ArrowRight") onRightFocus?.();
    if (e.key === "ArrowLeft") onLeftFocus?.();
    if (["ArrowUp", "ArrowDown"].includes(e.key)) {
      const step = e.key === "ArrowUp" ? 1 : -1;
      const newValue = getArrowByType(calculatedValue, step, picker);
      if (flag) setFlag(false);
      const tempDate = new Date(date);
      onSetDate(setDateByType(tempDate, newValue, picker, period));
    }
    if (e.key >= "0" && e.key <= "9") {
      if (picker === "12hours") setPrevIntKey(e.key);
      const newValue = calculateNewValue(e.key);
      if (flag) onRightFocus?.();
      setFlag(prev => !prev);
      const tempDate = new Date(date);
      onSetDate(setDateByType(tempDate, newValue, picker, period));
    }
  }, [onRightFocus, onLeftFocus, calculatedValue, picker, flag, date, onSetDate, period, calculateNewValue, onKeyDown]);
  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    onChange?.(e);
  }, [onChange]);
  return <Input ref={ref} id={id ?? picker} name={name ?? picker} className={cn("focus:bg-accent-background focus:text-accent-foreground w-[48px] text-center font-mono text-base tabular-nums caret-transparent [&::-webkit-inner-spin-button]:appearance-none", className)} value={value ?? calculatedValue} onChange={handleChange} type={type} inputMode="decimal" onKeyDown={handleKeyDown} {...props} />;
});
TimePickerInput.displayName = "TimePickerInput";
export { TimePickerInput };