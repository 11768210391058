"use client";

import * as PopoverPrimitive from "@radix-ui/react-popover";
import * as React from "react";
import type { IScrollState } from "../../hooks/useScrollState";
import { cn } from "../../lib/utils";
const Popover = PopoverPrimitive.Root;
const PopoverTrigger = PopoverPrimitive.Trigger;
const PopoverCloseButton = PopoverPrimitive.PopoverClose;
const PopoverClose = PopoverPrimitive.Close;
const PopoverPortal = PopoverPrimitive.Portal;
const PopoverContent = React.forwardRef<React.ElementRef<typeof PopoverPrimitive.Content>, React.ComponentPropsWithoutRef<typeof PopoverPrimitive.Content>>(({
  className,
  align = "center",
  sideOffset = 4,
  ...props
}, ref) => <PopoverPrimitive.Content ref={ref} align={align} sideOffset={sideOffset} className={cn("bg-popover text-popover-foreground data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2 z-50 w-72 rounded-md border p-4 shadow-sm outline-none", className)} {...props} />);
PopoverContent.displayName = PopoverPrimitive.Content.displayName;
interface IPopoverFooterProps {
  className?: string;
  scrollState?: IScrollState;
  children: React.ReactNode;
}
const PopoverFooter: React.FC<IPopoverFooterProps> = ({
  className,
  scrollState,
  children
}) => {
  return <div className={cn("bg-muted-background flex flex-row justify-end gap-x-2 border-t p-4 transition-shadow", scrollState != null && scrollState !== "end" ? "shadow-[0px_-2px_8px_0px_hsl(var(--gray-300))]" : "", className)} data-sentry-component="PopoverFooter" data-sentry-source-file="popover.tsx">
      {children}
    </div>;
};
PopoverFooter.displayName = "PopoverFooter";
export { Popover, PopoverClose, PopoverCloseButton, PopoverContent, PopoverFooter, PopoverPortal, PopoverTrigger };