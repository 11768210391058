"use client";

import * as SheetPrimitive from "@radix-ui/react-dialog";
import { cva, type VariantProps } from "class-variance-authority";
import * as React from "react";
import { cn } from "../../lib/utils";
import { Icon } from "./icon";
const Sheet = SheetPrimitive.Root;
const SheetTrigger = SheetPrimitive.Trigger;
const SheetClose = SheetPrimitive.Close;
const SheetPortal = (props: SheetPrimitive.DialogPortalProps): JSX.Element => <SheetPrimitive.Portal {...props} data-sentry-element="unknown" data-sentry-component="SheetPortal" data-sentry-source-file="sheet.tsx" />;
SheetPortal.displayName = SheetPrimitive.Portal.displayName;
const SheetOverlay = React.forwardRef<React.ElementRef<typeof SheetPrimitive.Overlay>, React.ComponentPropsWithoutRef<typeof SheetPrimitive.Overlay>>(({
  className,
  ...props
}, ref) => <SheetPrimitive.Overlay className={cn("bg-paper/20 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 fixed inset-0 z-50 backdrop-blur-sm", className)} {...props} ref={ref} />);
SheetOverlay.displayName = SheetPrimitive.Overlay.displayName;
const sheetVariants = cva("fixed z-50 gap-4 overflow-hidden rounded-2xl bg-paper shadow-lg transition ease-in-out data-[state=closed]:duration-300 data-[state=open]:duration-500 data-[state=open]:animate-in data-[state=closed]:animate-out", {
  variants: {
    side: {
      top: "inset-x-0 top-0 rounded-t-none border border-t-0 data-[state=closed]:slide-out-to-top data-[state=open]:slide-in-from-top",
      bottom: "inset-x-0 bottom-0 rounded-b-none border border-b-0 data-[state=closed]:slide-out-to-bottom data-[state=open]:slide-in-from-bottom",
      left: "inset-y-0 left-0 h-full rounded-l-none border border-l-0 data-[state=closed]:slide-out-to-left data-[state=open]:slide-in-from-left sm:max-w-[90%]",
      right: "inset-y-0 right-0 h-full rounded-r-none  border border-r-0 data-[state=closed]:slide-out-to-right data-[state=open]:slide-in-from-right sm:max-w-[90%]"
    },
    padding: {
      default: "p-6",
      none: "p-0"
    }
  },
  defaultVariants: {
    side: "right",
    padding: "default"
  }
});
interface ISheetContentProps extends React.ComponentPropsWithoutRef<typeof SheetPrimitive.Content>, VariantProps<typeof sheetVariants> {
  overlayProps?: React.ComponentPropsWithoutRef<typeof SheetOverlay>;
  hideClose?: boolean;
}
const SheetContent = React.forwardRef<React.ElementRef<typeof SheetPrimitive.Content>, ISheetContentProps>(({
  side = "right",
  padding = "default",
  className,
  children,
  overlayProps,
  hideClose,
  ...props
}, ref) => <SheetPortal>
      <SheetOverlay {...overlayProps} />
      <SheetPrimitive.Content ref={ref} className={cn(sheetVariants({
    side,
    padding
  }), className)} {...props}>
        {children}
        {hideClose !== true && <SheetPrimitive.Close className="absolute right-4 top-4 rounded-sm opacity-70 ring-offset-paper transition-opacity hover:opacity-100 focus:outline-none focus:ring-1 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-secondary">
            <Icon name="x" />
            <span className="sr-only">Close</span>
          </SheetPrimitive.Close>}
      </SheetPrimitive.Content>
    </SheetPortal>);
SheetContent.displayName = SheetPrimitive.Content.displayName;
const SheetHeader = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>): JSX.Element => <div className={cn("flex flex-col space-y-2 text-center sm:text-left", className)} {...props} data-sentry-component="SheetHeader" data-sentry-source-file="sheet.tsx" />;
SheetHeader.displayName = "SheetHeader";
const SheetFooter = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>): JSX.Element => <div className={cn("flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2", className)} {...props} data-sentry-component="SheetFooter" data-sentry-source-file="sheet.tsx" />;
SheetFooter.displayName = "SheetFooter";
const SheetTitle = React.forwardRef<React.ElementRef<typeof SheetPrimitive.Title>, React.ComponentPropsWithoutRef<typeof SheetPrimitive.Title>>(({
  className,
  ...props
}, ref) => <SheetPrimitive.Title ref={ref} className={cn("text-ink text-lg font-medium", className)} {...props} />);
SheetTitle.displayName = SheetPrimitive.Title.displayName;
const SheetDescription = React.forwardRef<React.ElementRef<typeof SheetPrimitive.Description>, React.ComponentPropsWithoutRef<typeof SheetPrimitive.Description>>(({
  className,
  ...props
}, ref) => <SheetPrimitive.Description ref={ref} className={cn("text-muted-foreground text-sm", className)} {...props} />);
SheetDescription.displayName = SheetPrimitive.Description.displayName;
export { Sheet, SheetClose, SheetContent, SheetDescription, SheetFooter, SheetHeader, SheetTitle, SheetTrigger };