"use client";

import type { ImperativePanelHandle } from "react-resizable-panels";
import * as ResizablePrimitive from "react-resizable-panels";
import { cn } from "../../lib/utils";
import { Icon } from "./icon";
const ResizablePanelGroup = ({
  className,
  ...props
}: React.ComponentProps<typeof ResizablePrimitive.PanelGroup>): React.ReactNode => <ResizablePrimitive.PanelGroup className={cn("flex h-full w-full data-[panel-group-direction=vertical]:flex-col", className)} {...props} data-sentry-element="unknown" data-sentry-component="ResizablePanelGroup" data-sentry-source-file="resizable.tsx" />;
const ResizablePanel = ResizablePrimitive.Panel;
const ResizableHandle = ({
  withHandle,
  className,
  ...props
}: React.ComponentProps<typeof ResizablePrimitive.PanelResizeHandle> & {
  withHandle?: boolean;
}): React.ReactNode => <ResizablePrimitive.PanelResizeHandle className={cn("bg-border focus-visible:ring-ring group relative flex w-[2px] items-center justify-center after:absolute after:inset-y-0 after:left-1/2 after:w-1 after:-translate-x-1/2 hover:bg-blue-500 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-offset-1 group-hover:bg-blue-500 data-[panel-group-direction=vertical]:h-px data-[panel-group-direction=vertical]:w-full data-[resize-handle-active]:bg-blue-500 data-[panel-group-direction=vertical]:after:left-0 data-[panel-group-direction=vertical]:after:h-1 data-[panel-group-direction=vertical]:after:w-full data-[panel-group-direction=vertical]:after:-translate-y-1/2 data-[panel-group-direction=vertical]:after:translate-x-0 [&[data-panel-group-direction=vertical]>div]:rotate-90", className)} {...props} data-sentry-element="unknown" data-sentry-component="ResizableHandle" data-sentry-source-file="resizable.tsx">
    {withHandle === true && <div className="z-10 flex h-4 w-3 items-center justify-center rounded-sm border bg-border  group-hover:bg-primary group-hover:text-white data-[resize-handle-active]:bg-blue-500 group-data-[resize-handle-active]:bg-primary group-data-[resize-handle-active]:text-white">
        <Icon name="grip-vertical" className="size-2.5" />
      </div>}
  </ResizablePrimitive.PanelResizeHandle>;
export { type ImperativePanelHandle, ResizableHandle, ResizablePanel, ResizablePanelGroup };