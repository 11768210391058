"use client";

import type { HTMLInputTypeAttribute, RefObject } from "react";
import React, { useCallback } from "react";
import type { ControllerProps, ControllerRenderProps, FieldPath, FieldValues } from "react-hook-form";
import { FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from "../../atoms/form";
import { Input } from "../../atoms/input";
import { Textarea } from "../../atoms/textarea";
export interface IFormInput<TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>> extends Omit<ControllerProps<TFieldValues, TName>, "render"> {
  inputRef?: React.ForwardedRef<HTMLInputElement | HTMLTextAreaElement>;
  type?: HTMLInputTypeAttribute | "textarea";
  label?: React.ReactNode;
  labelRight?: React.ReactNode;
  description?: React.ReactNode;
  placeholder?: string;
  disabled?: boolean;
  className?: string;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  rows?: number;
}
export function FormInput<TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>({
  inputRef,
  label,
  labelRight,
  type,
  description,
  placeholder,
  disabled,
  className,
  onKeyDown,
  onFocus,
  onBlur,
  rows,
  ...props
}: IFormInput<TFieldValues, TName>): JSX.Element {
  const handleKeyDown = useCallback((e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (onKeyDown != null) {
      onKeyDown(e);
    }
  }, [onKeyDown]);
  const handleFocus = useCallback((e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (onFocus != null) {
      onFocus(e);
    }
  }, [onFocus]);
  const handleBlur = useCallback((e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (onBlur != null) {
      onBlur(e);
    }
  }, [onBlur]);
  const renderInput = useCallback(({
    field
  }: {
    field: ControllerRenderProps<TFieldValues, TName>;
  }): JSX.Element => {
    return <FormItem>
          {label != null && <div className="flex items-center justify-between">
              <FormLabel>{label}</FormLabel>
              {labelRight}
            </div>}
          <FormControl>
            {type === "textarea" ? <Textarea {...field} ref={inputRef as RefObject<HTMLTextAreaElement>} className={className} placeholder={placeholder} disabled={disabled} onKeyDown={handleKeyDown} onFocus={handleFocus} onBlur={handleBlur} rows={rows} /> : <Input {...field} ref={inputRef as RefObject<HTMLInputElement>} type={type} className={className} placeholder={placeholder} disabled={disabled} onKeyDown={handleKeyDown} onFocus={handleFocus} onBlur={handleBlur} />}
          </FormControl>
          {description != null && <FormDescription>{description}</FormDescription>}
          <FormMessage />
        </FormItem>;
  }, [inputRef, label, labelRight, type, description, placeholder, disabled, className, handleKeyDown, handleFocus, handleBlur, rows]);
  return <FormField {...props} render={renderInput} data-sentry-element="FormField" data-sentry-component="FormInput" data-sentry-source-file="FormInput.tsx" />;
}