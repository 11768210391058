import * as React from "react";
import { useCallback, useState } from "react";
import { cn } from "../../lib/utils";
import { Button } from "./button";
import { inputVariants } from "./input";
export type ITextareaProps = React.TextareaHTMLAttributes<HTMLTextAreaElement> & {
  small?: boolean;
};
const TextareaBase = React.forwardRef<HTMLTextAreaElement, ITextareaProps>(({
  className,
  onKeyDown,
  small,
  ...props
}, ref): JSX.Element => {
  const handleKeyDown = useCallback((e: React.KeyboardEvent<HTMLTextAreaElement>): boolean | void => {
    // Submit form on cmd+enter (mac) or ctrl+enter (windows
    if (e.metaKey && e.key === "Enter") {
      const form = e.currentTarget.closest("form");
      if (form) {
        form.requestSubmit();
        return false;
      }
    }
    if (onKeyDown) {
      return onKeyDown(e);
    }
  }, [onKeyDown]);
  return <textarea ref={ref} onKeyDown={handleKeyDown} className={cn(inputVariants({
    size: small === true ? "small" : "default"
  }), className)} {...props} />;
});
TextareaBase.displayName = "TextareaBase";
const Textarea = React.forwardRef<HTMLTextAreaElement, ITextareaProps>(({
  className,
  ...props
}, ref): JSX.Element => {
  return <TextareaBase className={className} ref={ref} {...props} />;
});
Textarea.displayName = "Textarea";
const TextareaWithButton = React.forwardRef<HTMLTextAreaElement, ITextareaProps & {
  defaultValue?: string;
  buttonText?: string;
  isLoading?: boolean;
  onSubmitButton: (value: string) => void;
}>(({
  placeholder,
  disabled,
  small,
  onChange,
  defaultValue,
  buttonText,
  isLoading,
  onSubmitButton,
  ...props
}, ref) => {
  const [value, setValue] = useState<string | undefined>(defaultValue);
  const handleSubmit = useCallback(() => {
    if (value != null) {
      onSubmitButton?.(value);
    }
  }, [value, onSubmitButton]);
  const handleChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.target.value);
    if (onChange) {
      onChange(e);
    }
  }, [onChange]);
  return <div className="relative w-full">
      <TextareaBase ref={ref} placeholder={placeholder} onChange={handleChange} className="size-full min-h-32 resize-none" defaultValue={value} disabled={disabled} small={small} {...props} />
      <Button size="xs" className="absolute bottom-2 right-2 z-10 disabled:opacity-50" disabled={disabled} iconRight="chevron-right" isLoading={isLoading} onClick={handleSubmit}>
        {buttonText}
      </Button>
    </div>;
});
TextareaWithButton.displayName = "TextareaWithButton";
export { Textarea, TextareaBase, TextareaWithButton };