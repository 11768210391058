import { useCallback, useEffect, useMemo, useState } from "react";
import { cn } from "../../lib/utils";
import { Icon } from "./icon";
import { Spinner } from "./spinner";
interface ICommentToast {
  className?: string;
  status: "loading" | "success" | "error";
  onClick?: () => void;
}
export const CommentToast: React.FC<ICommentToast> = ({
  status,
  className,
  onClick
}) => {
  const text = status === "loading" ? "posting comment" : status === "success" ? "comment posted" : "error posting comment";
  const [showSuccessCheck, setShowSuccessCheck] = useState(false);
  useEffect(() => {
    if (status === "success") {
      setShowSuccessCheck(true);
      const timeout = setTimeout(() => {
        setShowSuccessCheck(false);
      }, 1000);
      return (): void => clearTimeout(timeout);
    }
  }, [status]);
  const icon = useMemo(() => {
    const iconBg = status === "loading" ? "bg-primary" : status === "success" ? showSuccessCheck ? "bg-success" : "bg-paper" : "bg-error";
    const iconColor = status === "success" && !showSuccessCheck ? "text-primary" : "text-white";
    const inner = status === "loading" ? <Spinner className="size-3" /> : status === "success" ? showSuccessCheck ? <Icon name="check" strokeWidth={2} className="size-3" /> : <Icon name="arrow-down" strokeWidth={2} className="size-3" /> : <Icon name="x" strokeWidth={2} className="size-3" />;
    return <div className={cn("flex h-4 w-4 items-center justify-center rounded-full text-white transition-all", iconColor, iconBg)}>
        {inner}
      </div>;
  }, [status, showSuccessCheck]);
  const handleClick = useCallback(() => {
    onClick?.();
  }, [onClick]);
  return <div className={cn("shadow-uniform bg-paper flex flex-row gap-2 rounded-full py-1 pl-1 pr-3 text-sm !shadow-gray-300", className)} onClick={handleClick} data-sentry-component="CommentToast" data-sentry-source-file="comment-toast.tsx">
      {icon}
      {text}
    </div>;
};