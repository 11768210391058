import * as React from "react";
import { cn } from "../../lib/utils";
import { Badge } from "./badge";
export const ByAiAssistant: React.FC<{
  className?: string;
}> = ({
  className
}) => <div className={cn("flex items-center space-x-1", className)} data-sentry-component="ByAiAssistant" data-sentry-source-file="by-ai-assistant.tsx">
    <Badge iconLeft="sparkles" size="sm" className="bg-gray-50 text-purple-600 hover:bg-gray-50" data-sentry-element="Badge" data-sentry-source-file="by-ai-assistant.tsx">
      Autofilled by AI
    </Badge>
  </div>;