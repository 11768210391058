import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "../atoms/tooltip";
interface ICommentTimestampProps {
  timestamp: Date;
}
const UPDATE_INTERVAL = 60000;
export const CommentTimestamp = ({
  timestamp
}: ICommentTimestampProps): JSX.Element => {
  const [, setTime] = useState(new Date());

  // Force a re-render every minute to update the relative time
  useEffect(() => {
    const interval = setInterval(() => {
      setTime(new Date());
    }, UPDATE_INTERVAL);
    return (): void => clearInterval(interval);
  }, []);
  return <TooltipProvider data-sentry-element="TooltipProvider" data-sentry-component="CommentTimestamp" data-sentry-source-file="comment-timestamp.tsx">
      <Tooltip data-sentry-element="Tooltip" data-sentry-source-file="comment-timestamp.tsx">
        <TooltipTrigger data-sentry-element="TooltipTrigger" data-sentry-source-file="comment-timestamp.tsx">{DateTime.fromJSDate(timestamp).toRelative()}</TooltipTrigger>
        <TooltipContent data-sentry-element="TooltipContent" data-sentry-source-file="comment-timestamp.tsx">{DateTime.fromJSDate(timestamp).toLocaleString(DateTime.DATETIME_MED)}</TooltipContent>
      </Tooltip>
    </TooltipProvider>;
};