"use client";

import * as React from "react";
import { cn } from "../../lib/utils";
interface ISidebarContentProps {
  className?: string;
  children: React.ReactNode;
  onScroll?: React.UIEventHandler<HTMLElement>;
}
export const SidebarContent = React.forwardRef<React.ElementRef<React.ForwardRefExoticComponent<React.RefAttributes<HTMLDivElement>>>, ISidebarContentProps>(({
  className,
  onScroll,
  children
}, ref) => {
  const handleScroll = onScroll;
  return <div ref={ref} className={cn("bg-paper flex grow flex-col overflow-auto rounded-xl", className)} onScroll={handleScroll}>
      {children}
    </div>;
});
SidebarContent.displayName = "SidebarContent";